import React from 'react';
import { Typography, Box } from '@mui/material';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ArticleIcon from '@mui/icons-material/Article';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Device } from '@brightlayer-ui/icons-mui';
import { Drawer, DrawerBody, DrawerLayout, DrawerNavGroup, DrawerRailItem } from '@brightlayer-ui/react-components';
import { useNavigate } from 'react-router-dom';

export const SideNav = (props: { isErrorForProfileDetails: boolean }): React.JSX.Element => {
    /*Additional hooks*/
    const navigate = useNavigate();

    /*Variables*/
    const pathName: any = window?.location?.pathname;

    //active tabs for summary
    const isActiveTab = pathName.includes('/summary');

    /*Helper-Utility functions*/
    const routeHandler = (route: string): void => {
        navigate(route);
    };

    return pathName !== '/' && !pathName.includes('/newUserBackground') ? (
        <Box className="left-content-wrapper">
            <Box className="side-nav-wrapper">
                <DrawerLayout
                    drawer={
                        <Drawer open variant="rail" className="drawer-wrapper">
                            <DrawerBody>
                                <DrawerNavGroup className="side-nav-list">
                                    <DrawerRailItem
                                        className={`side-nav-list-item ${isActiveTab ? 'sidenav-active-tab' : ''}
                                            }`}
                                        title="Summary"
                                        icon={<SummarizeIcon />}
                                        itemID="5"
                                        divider
                                        disabled={props?.isErrorForProfileDetails}
                                        onClick={(): void => routeHandler('/summary')}
                                    />
                                    <DrawerRailItem
                                        className={`side-nav-list-item ${
                                            pathName.includes('/deviceTable') || pathName.includes('/noProfileData')
                                                ? 'sidenav-active-tab'
                                                : ''
                                        }`}
                                        title="Devices"
                                        icon={<Device />}
                                        itemID="2"
                                        divider
                                        disabled={props?.isErrorForProfileDetails}
                                        onClick={(): void => routeHandler('/deviceTable')}
                                    />
                                    <DrawerRailItem
                                        className={`side-nav-list-item ${
                                            pathName.includes('/firmwareTabs') ? 'sidenav-active-tab' : ''
                                        }`}
                                        title="Firmware"
                                        icon={<DeveloperBoardIcon />}
                                        itemID="4"
                                        divider
                                        disabled={props?.isErrorForProfileDetails}
                                        onClick={(): void => routeHandler('/firmwareTabs/overview')}
                                    />
                                    <DrawerRailItem
                                        className={`side-nav-list-item ${
                                            pathName.includes('/auditLogs') ? 'sidenav-active-tab' : ''
                                        }`}
                                        title="Audit Logs"
                                        icon={<ArticleIcon />}
                                        itemID="5"
                                        divider
                                        disabled={props?.isErrorForProfileDetails}
                                        onClick={(): void => routeHandler('/auditLogs')}
                                    />
                                </DrawerNavGroup>
                                <Typography className="side-nav-footer">Version - 2.2.2</Typography>
                            </DrawerBody>
                        </Drawer>
                    }
                ></DrawerLayout>
            </Box>
        </Box>
    ) : (
        <></>
    );
};
